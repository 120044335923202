import { Box, CircularProgress } from "@mui/material";
export const LoadingModal = () => {
  return <Box sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px"
  }} data-sentry-element="Box" data-sentry-component="LoadingModal" data-sentry-source-file="LoadingModal.tsx">
			<CircularProgress data-sentry-element="CircularProgress" data-sentry-source-file="LoadingModal.tsx" />
		</Box>;
};