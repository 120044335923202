"use client";

import { Box, Container, TextField, Typography, Link } from "@mui/material";
import { useIsAuthenticated } from "@azure/msal-react";
import React, { FC, useState } from "react";
import Image from "next/image";
import { redirect } from "next/navigation";
import { b2cLoginRequest, loginRequest } from "authConfig";
import { colors } from "@components/ThemeRegistry/theme";
import { SquareNavyButton } from "@components/Common";
import { SignInButton } from "@components/SignInButton";
import { MsalInstanceFactory } from "@components/Msal";
import { B2C_LOGIN_TYPE, B2B_LOGIN_TYPE } from "clientConst";
import { setLoginType } from "@utils/helpers";
import { LoadingOverlay } from "@components/Loading";
import { PowerBIReportFilterStorage } from "definitions";
const LoginPage: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const [navigating, setNavigating] = useState<boolean>(false);
  if (isAuthenticated) {
    redirect("/");
  }
  const handleLoginB2B = () => {
    setLoginType(B2B_LOGIN_TYPE);
    removeOldUserCaches();
    setNavigating(true);
    const instance = MsalInstanceFactory(false);
    instance.loginRedirect(loginRequest).catch(err => {
      console.error(err);
      setNavigating(false);
      //TODO: show an error message to the user. When a snackbar or a toast is implemented, use it here.
    });
  };
  const handleLoginB2C = () => {
    setLoginType(B2C_LOGIN_TYPE);
    removeOldUserCaches();
    setNavigating(true);
    const instance = MsalInstanceFactory(true);
    instance.loginRedirect(b2cLoginRequest).catch(err => {
      console.error(err);
      setNavigating(false);
      //TODO: show an error message to the user. When a snackbar or a toast is implemented, use it here.
    });
  };
  const removeOldUserCaches = () => {
    //Remove user cache data due to permission changes.
    sessionStorage.removeItem("categories");
    sessionStorage.removeItem("datasetIDs");
    sessionStorage.removeItem("entityIDs");
    PowerBIReportFilterStorage.clearFilterStorages();
  };
  return <Container maxWidth='sm' sx={{
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }} data-sentry-element="Container" data-sentry-component="LoginPage" data-sentry-source-file="page.tsx">
			<Box data-sentry-element="Box" data-sentry-source-file="page.tsx">
				{navigating && <LoadingOverlay zIndex={10005} alpha="0.3" />}
				<Box sx={{
        width: "34rem",
        py: 4,
        px: "64px",
        borderRadius: 10,
        boxShadow: "0px 0px 1rem 1rem #DDD",
        m: "auto",
        "@media screen and (max-width: 1366px) and (max-height: 768px)": {
          width: "30rem",
          py: 2,
          px: "48px",
          borderRadius: 8
        },
        "@media screen and (max-width: 767px) and (max-height: 1023px)": {
          width: "100%",
          py: 0,
          px: 1,
          boxShadow: "none"
        }
      }} data-sentry-element="Box" data-sentry-source-file="page.tsx">
					<Box display='flex' justifyContent='center' alignItems='center' data-sentry-element="Box" data-sentry-source-file="page.tsx">
						<Image src='/cc-logo-green.svg' alt='Card Compliant' width={250} height={100} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
					</Box>

					<Typography align='left' sx={{
          mt: 2,
          fontWeight: 700,
          "@media screen and (max-width: 1366px) and (max-height: 768px)": {
            mt: "12px"
          }
        }} data-sentry-element="Typography" data-sentry-source-file="page.tsx">
						Sign in with your email address
					</Typography>

					<TextField fullWidth id='username' label='Email' variant='outlined' margin='normal' sx={{
          "@media screen and (max-width: 1366px) and (max-height: 768px)": {
            mt: "12px"
          }
        }} data-sentry-element="TextField" data-sentry-source-file="page.tsx" />
					<TextField fullWidth id='password' label='Password' variant='outlined' margin='normal' type='password' sx={{
          "@media screen and (max-width: 1366px) and (max-height: 768px)": {
            mt: "12px"
          }
        }} data-sentry-element="TextField" data-sentry-source-file="page.tsx" />

					<Box data-sentry-element="Box" data-sentry-source-file="page.tsx">
						<Typography sx={{
            fontSize: ".875em"
          }} data-sentry-element="Typography" data-sentry-source-file="page.tsx">
							<Link href='#' underline='hover' color={colors.navy} fontWeight={500} data-sentry-element="Link" data-sentry-source-file="page.tsx">
								Forgot your password?
							</Link>
						</Typography>
					</Box>

					<SquareNavyButton variant='contained' size='small' sx={{
          mt: "12px",
          minHeight: 36,
          width: "50%"
        }} data-sentry-element="SquareNavyButton" data-sentry-source-file="page.tsx">
						<Typography data-sentry-element="Typography" data-sentry-source-file="page.tsx">Sign In</Typography>
					</SquareNavyButton>

					<Typography align='left' sx={{
          mt: 4,
          fontWeight: 700,
          "@media screen and (max-width: 1366px) and (max-height: 768px)": {
            mt: 2
          }
        }} data-sentry-element="Typography" data-sentry-source-file="page.tsx">
						Sign in with SSO
					</Typography>
					<SignInButton iconSrc='./ms-icon.png' text='Microsoft Account' actionFn={handleLoginB2C} data-sentry-element="SignInButton" data-sentry-source-file="page.tsx" />

					<Typography align='left' sx={{
          mt: 4,
          fontWeight: 700,
          "@media screen and (max-width: 1366px) and (max-height: 768px)": {
            mt: 2
          }
        }} data-sentry-element="Typography" data-sentry-source-file="page.tsx">
						Sign in with Card Compliant
					</Typography>
					<SignInButton iconSrc='./cc-icon.png' text='Card Compliant' actionFn={handleLoginB2B} data-sentry-element="SignInButton" data-sentry-source-file="page.tsx" />

					<Typography align='center' sx={{
          mt: 4,
          fontSize: ".85em",
          "@media screen and (max-width: 1366px) and (max-height: 768px)": {
            mt: 2
          }
        }} data-sentry-element="Typography" data-sentry-source-file="page.tsx">
						Copyright 2024 © Card Compliant. All rights reserved.
					</Typography>
				</Box>
			</Box>
		</Container>;
};
export default LoginPage;