"use client";

import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
export const SignInButton = ({
  iconSrc,
  text,
  actionFn
}: {
  iconSrc: string;
  text: string;
  actionFn: () => void;
}) => {
  return <Button onClick={actionFn} fullWidth sx={{
    position: "relative",
    mt: 2,
    py: 1,
    borderRadius: 8,
    bgcolor: "common.white",
    textTransform: "none",
    boxShadow: "0px 0px 5px 5px #DDD",
    "&:hover": {
      backgroundColor: "common.white"
    },
    "@media screen and (max-width: 1366px) and (max-height: 768px)": {
      mt: "12px"
    }
  }} data-sentry-element="Button" data-sentry-component="SignInButton" data-sentry-source-file="SignInButton.tsx">
			<Box sx={{
      position: "absolute",
      left: "18px",
      top: "50%",
      transform: "translateY(-50%)"
    }} component='img' src={iconSrc} alt={text} width={33} height={33} data-sentry-element="Box" data-sentry-source-file="SignInButton.tsx" />
			<Box width={35} height={35} data-sentry-element="Box" data-sentry-source-file="SignInButton.tsx" />
			<Box sx={{
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)"
    }} data-sentry-element="Box" data-sentry-source-file="SignInButton.tsx">
				<Typography fontWeight='bold' color='common.black' data-sentry-element="Typography" data-sentry-source-file="SignInButton.tsx">
					{text}
				</Typography>
			</Box>
		</Button>;
};