import { Box, CircularProgress } from "@mui/material";
export const LoadingOverlay = ({
  zIndex,
  alpha
}: {
  zIndex: number;
  alpha: string;
}) => {
  return <Box sx={{
    position: "absolute",
    top: "-24px",
    left: "-24px",
    bottom: "-24px",
    right: "-24px",
    height: "calc(100vh - 64px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: `rgba(255, 255, 255, ${alpha})`,
    zIndex: zIndex
  }} data-sentry-element="Box" data-sentry-component="LoadingOverlay" data-sentry-source-file="LoadingOverlay.tsx">
        <CircularProgress data-sentry-element="CircularProgress" data-sentry-source-file="LoadingOverlay.tsx" />
    </Box>;
};